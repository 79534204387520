// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hypnobirthing-js": () => import("./../../../src/pages/hypnobirthing.js" /* webpackChunkName: "component---src-pages-hypnobirthing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meditation-js": () => import("./../../../src/pages/meditation.js" /* webpackChunkName: "component---src-pages-meditation-js" */),
  "component---src-pages-osandojo-js": () => import("./../../../src/pages/osandojo.js" /* webpackChunkName: "component---src-pages-osandojo-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

